export const environment = {
    production: false,
    instrumentationKey: '2d762043-d274-474b-be04-6b9e5c49e455',
    wootricCsat: "a734f093",
    wootricCsatDev: false,
    url: "https://qa-api-monitorobras.azurewebsites.net/",
    urlEmpresa: "https://qa-api-consulta-emp-sso.azurewebsites.net",
    gTagManagerCode: "GTM-KZ93DWH",
    urlApiSSO: 'https://qa-api-login.azurewebsites.net/'
};
