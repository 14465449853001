import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Router } from "@angular/router";
import { ServicioAutenticacion } from "./_services/autenticacion.service";

declare var $: any;

@Component({
    selector: "app-login",
    template: ''
})
export class IngresoComponent implements OnInit {

    constructor(
        private servicioAutenticacion: ServicioAutenticacion,
        private route: ActivatedRoute,
        private router: Router) {
    }

    public onSubmit() {
    }

    private handleMessage = (event: interfaces.ParentEvent) => {
        const dataFromParent = event.data;

        if (dataFromParent.currentUser != null) {
            const currentUser: interfaces.UsuarioActual = JSON.parse(dataFromParent.currentUser);
            const currentSuscripcion: interfaces.Suscripcion = JSON.parse(dataFromParent.currentSuscription);
            const currentPortal: interfaces.Portal = JSON.parse(dataFromParent.currentPortal);
            const dataHotjar: string = dataFromParent.dataHotjar;

            if (currentUser != null) {
                if (dataFromParent.Callback == "login" || dataFromParent.Callback == "refresh") {

                    const idPortal = currentPortal == null ? "Ax8=" : currentPortal.id;
                    const idSuscripcion = currentSuscripcion == null ? null : currentSuscripcion.id;

                    if (idSuscripcion == null) { return; }

                    this.servicioAutenticacion.ingresarPortalSuscripcion(idPortal, idSuscripcion, currentUser, dataHotjar);

                    if (dataFromParent.Callback == "login") {
                        this.listoToken(idPortal, idSuscripcion, currentUser);

                        setInterval(() => {
                            if (this.servicioAutenticacion.expiredToken()) {
                                this.servicioAutenticacion.refreshToken();
                            }
                        }, 60000);
                    }
                    if (dataFromParent.Callback == "refresh") {

                    }
                }
            }
        }
    }

    private listoToken = (idPortal: string, idSuscripcion: string, currentUser: interfaces.UsuarioActual) => {
        this.route.paramMap.subscribe((params: ParamMap) => {
            const path = params.get("path") || "";
            if (path) {
                this.router.navigate([path]);
            } else {
                this.router.navigate(["catalogo"]);
            }
        });
    }

    public ngOnInit() {
        localStorage.setItem("FiltrosObra", null);
        localStorage.setItem("consultaPagActual", "1");

        if (window.addEventListener) {
            window.addEventListener("message", this.handleMessage);
        } else {
            (<any>window).attachEvent("onmessage", this.handleMessage);
        }
        parent.postMessage({ RequestIdT: true, Callback: "login" }, "*");
    }
}


export namespace interfaces {
    export interface Empresa {
        id: number;
        codigo: string;
        nombre: string;
        fechacreacion: string
    }
    export interface Suscripcion {
        id: string;
        nombre: string;
        empresas: Empresa[];
    }
    export interface Portal {
        id: string;
        value: string;
        codigo: string;
    }
    export interface UsuarioActual {
        id: number;
        idToken: string;
        nombres: string;
        apellidos: string;
        user_name: string;
        telefono: any;
        suscripciones: Array<Suscripcion>;
        codigo: string;
        exitosa?: boolean;
        activo?: boolean;
        administrador?: boolean;
        mensajeLogin?: string;
        wizardComplete?: boolean;
        productos?: Array<number>;
        password?: string;
        confirmPassword?: string;
        federada?: boolean;
        suscripcionDefault?: string;
        fechaCreacion: Date;
        userPersona?: string;
        segment?: string;
    }

    export interface ParentMessage {
        Callback: string;
        currentPortal: string;
        currentSuscription: string;
        currentUser: string;
        dataHotjar: string;

    }
    export interface ParentEvent {
        origin: string;
        data: ParentMessage;
    }
}
