﻿import { Injectable } from "@angular/core";
//import { _throw } from "rxjs/Observable/throw";
import { Response } from "@angular/http";
import { Observable } from "rxjs/Observable";
import { interfaces } from "../ingreso.component";
import { HttpClient } from '@angular/common/http';
import "rxjs/add/observable/fromPromise";
import "rxjs/add/operator/toPromise";
import "rxjs/add/operator/catch";
import { CookieService } from "ngx-cookie-service";
import { map } from "rxjs/operators";
import { environment } from './../../environments/environment';

export interface LoginResponse {
    access_token: string;
    token_type: string;
    expires_in: number;
    userName: string;
    ".issued": string;
    ".expires": string;
}

export interface ISecurityUser {
    userName: string;
}

@Injectable({
  providedIn: 'root'
})
export class ServicioAutenticacion {

    static tokenKey = "icMarketPlaceToken";
    static portalKey = "icMarketPlacePortalKey";
    static suscripcionKey = "icMarketPlaceSuscripcionKey";
    static currentUser = "icCurrentUser";


    constructor(private cookieService: CookieService, private http: HttpClient) {
    }

    public estaAutenticado(): boolean {
        return this.currentIdTokenValue != null;
    }

    public get currentAccessTokenValue(): string {
        return this.cookieService.get('accessToken');
    }

    public get currentIdTokenValue(): string {
        return this.cookieService.get('idToken');
    }

    public get currentPermissionValue(): string {
        return this.cookieService.get('tokenPermisos');
    }

    public get currentRefreshTokenValue(): string {
        return this.cookieService.get('refreshToken');
    }


    public obtenerPortal(): string {
        return sessionStorage.getItem(ServicioAutenticacion.portalKey);
    }

    public obtenerIdSuscripcion(): string {
        return sessionStorage.getItem(ServicioAutenticacion.suscripcionKey);
    }

    public obtenerCurrentUser(): interfaces.UsuarioActual {
        return JSON.parse(sessionStorage.getItem(ServicioAutenticacion.currentUser));
    }

    public salir() {
        sessionStorage.removeItem(ServicioAutenticacion.tokenKey);
        sessionStorage.removeItem(ServicioAutenticacion.portalKey);
        sessionStorage.removeItem(ServicioAutenticacion.suscripcionKey);
        sessionStorage.removeItem(ServicioAutenticacion.currentUser);
    }

    public ingresarToken(token: string): Observable<ISecurityUser> {
        sessionStorage.setItem(ServicioAutenticacion.tokenKey, token);
        const usuario = <ISecurityUser>{ userName: "Por token" };
        return Observable.fromPromise(Promise.resolve(usuario));
    }

    public ingresarPortalSuscripcion(idportal: string, idsuscripcion: string, currentUser: interfaces.UsuarioActual, dataHotjar: string): Observable<ISecurityUser> {
        sessionStorage.setItem(ServicioAutenticacion.portalKey, idportal);
        sessionStorage.setItem(ServicioAutenticacion.suscripcionKey, idsuscripcion);
        sessionStorage.setItem(ServicioAutenticacion.tokenKey, currentUser.idToken);
        sessionStorage.setItem(ServicioAutenticacion.currentUser, JSON.stringify(currentUser));
        localStorage.setItem("dataHotjar", dataHotjar);
        const usuario = <ISecurityUser>{ userName: "Portal/Suscripcion" };
        return Observable.fromPromise(Promise.resolve(usuario));
    }

    private procesarLogin(res: Response): Observable<ISecurityUser> {
        const body = <LoginResponse>res.json();
        sessionStorage.setItem(ServicioAutenticacion.tokenKey, body.access_token);
        const usuario = <ISecurityUser>{ userName: body.userName };
        return Observable.fromPromise(Promise.resolve(usuario));

    }

    private procesarError(error: Response | any) {
        let errMsg: string;
        if (error instanceof Response) {
            const body = error.json() || "";
            const err = body.error || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return Observable.throw(errMsg);
    }

    private refreshTokenTimeout: any;
    private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.currentAccessTokenValue.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }

    start() {
        this.startRefreshTokenTimer();
    }

    refreshToken(): Observable<void> {
        return this.http.get<any>(`${environment.urlApiSSO}api/v2/identificacion/refreshtokensso?token=${this.currentRefreshTokenValue}`)
            .pipe(map(data => {
                if (data.exitosa) {
                    this.saveAccessToken(data.accessToken);
                    this.saveIdToken(data.idToken);
                    this.start();
                }
                else {
                    this.logout();
                }
            }));
    }

    logout() {
        this.stopRefreshTokenTimer();
        // this.logoutUser();
        // this.logoutSuscripcion();
        // this.logoutPortal();
    }

    saveAccessToken(accessToken: string) {
        const jwtToken = JSON.parse(atob(accessToken.split('.')[1]));
        const d = new Date(0);
        d.setUTCSeconds(jwtToken.exp);
        this.cookieService.set('accessToken', accessToken, d, '/', 'iconstruye.cl', false, 'Lax');
    }

    public expiredToken(): boolean {
        const token = this.currentAccessTokenValue;
        const tokenJson = JSON.parse(atob(token.split(".")[1]));
        const fecha = tokenJson.exp;
        return Math.round(Date.now() / 1000) >= fecha;
    }

    public execute(): Observable<string> {
        if (!this.expiredToken()) {
            return Observable.fromPromise(Promise.resolve("OK"));
        }
        this.refreshToken();
        return Observable.throw("Ha ocurrido un error, por favor intentar nuevamente");
    }

    saveIdToken(idToken: string) {
        const jwtToken = JSON.parse(atob(idToken.split('.')[1]));
        const d = new Date(0);
        d.setUTCSeconds(jwtToken.exp);
        this.cookieService.set('currentUser', idToken, d, '/', 'iconstruye.cl', false, 'Lax');
    }

}
