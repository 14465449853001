import { Injectable } from "@angular/core";
import { ServicioGateway } from "./gateway.service";
import { Wrapper } from "./wrapper.service";
import { Observable } from "rxjs/Observable";

@Injectable()
export class MonitorService {

    constructor(private gateway: ServicioGateway, private wrapper: Wrapper<any>) { }

    public buscarEmpresas(modelo: ApiModel.BuscarEmpresas): Observable<Mensaje.BuscarEmpresasRespuesta> {
        return this.gateway.post("Catalogo/BuscarEmpresas", modelo);
    }
    public buscarEmpresasPorRut(modelo: ApiModel.BuscarEmpresasPorRut): Observable<Mensaje.BuscarEmpresasPorRutRespuesta> {
        return this.gateway.post("Empresa/BuscarEmpresasPorRut", modelo);
    }
    public buscarArquitectos(modelo: ApiModel.BuscarArquitectos): Observable<Mensaje.BuscarArquitectosRespuesta> {
        return this.gateway.post("Catalogo/BuscarArquitectos", modelo);
    }

    public buscarRegiones(modelo: ApiModel.BuscarRegiones): Observable<Mensaje.BuscarRegionesRespuesta> {
        return this.gateway.post("Catalogo/BuscarRegiones", modelo);
    }

    public listarEtapasObra(modelo: ApiModel.ListarEtapasObra): Observable<Mensaje.ListarEtapasObraRespuesta> {
        return this.gateway.post("Catalogo/ListarEtapasObra", modelo);
    }

    public listarRubrosObra(modelo: ApiModel.ListarRubrosObra): Observable<Mensaje.ListarRubrosObraRespuesta> {
        return this.gateway.post("Catalogo/ListarRubrosObra", modelo);
    }

    public listarTiposObra(modelo: ApiModel.ListarTiposObra): Observable<Mensaje.ListarTiposObraRespuesta> {
        return this.gateway.post("Catalogo/ListarTiposObra", modelo);
    }

    public obtenerObra(modelo: ApiModel.Catalogo.ObtenerObra): Observable<Mensaje.ObtenerObraRespuesta> {
        return this.gateway.post("Catalogo/ObtenerObra", modelo);
    }

    public abrirContactosObra(modelo: ApiModel.Catalogo.AbrirContactosObra): Observable<Mensaje.AbrirContactosObraRespuesta> {
        return this.gateway.post("Catalogo/AbrirContactosObra", modelo);
    }

    public obtenerCatalogo(modelo: ApiModel.Catalogo.Obtener): Observable<Mensaje.ObtenerCatalogoRespuesta> {
        return this.gateway.post("Catalogo/Obtener", modelo);
    }

    public buscarObras(modelo: ApiModel.Catalogo.Listar): Observable<Mensaje.ListarObrasRespuesta> {
        return this.gateway.post("Catalogo/ListarObras", modelo);
    }

    public listarObrasDeConstructora(modelo: ApiModel.Catalogo.ListarDeConstructora): Observable<Mensaje.ListarObrasConstructoraRespuesta> {
        return this.gateway.post("Catalogo/ListarObrasDeConstructora", modelo);
    }

    public listarObrasRelacionadaFicha(modelo: ApiModel.Catalogo.ListarObrasRelacionadaFicha): Observable<Mensaje.ListarObrasRelacionadaFichaRespuesta> {
        return this.gateway.post("Catalogo/ListarObrasRelacionadaFicha", modelo);
    }

    public contactarObra(modelo: ApiModel.Catalogo.ContactarObra): Observable<Mensaje.ContactarObraRespuesta> {
        return this.gateway.post("Catalogo/ContactarObra", modelo);
    }
    // public obtenerCotizacion(modelo: ApiModel.Cotizacion.Obtener): Observable<Mensaje.ObtenerCotizacionRespuesta> {
    //     return this.gateway.post("Cotizacion/ObtenerCotizacion", modelo);
    // }

    // public obtenerPdfCotizacion(modelo: ApiModel.Cotizacion.ObtenerPdf): Observable<Mensaje.ObtenerCotizacionPdfRespuesta> {
    //     return this.gateway.post("Cotizacion/ObtenerPdfCotizacion", modelo);
    // }

    // public buscarCotizacionesDescartadas(modelo: ApiModel.Cotizacion.ListarDescartadas): Observable<Mensaje.CotizacionDescartadaRespuesta> {
    //     return this.gateway.post("Cotizacion/ListarCotizacionesDescartadas", modelo);
    // }

    // public obtenerOferta(modelo: ApiModel.Oferta.Obtener): Observable<Mensaje.ObtenerOfertaRespuesta> {
    //     return this.gateway.post("Cotizacion/ObtenerOferta", modelo);
    // }

    // public ObtenerPdfOferta(modelo: ApiModel.Oferta.ObtenerPdf): Observable<Mensaje.ObtenerOfertaPdfRespuesta> {
    //     return this.gateway.post("Cotizacion/ObtenerPdfOferta", modelo);
    // }


    // public continuarOfertaCotizacion(modelo: ApiModel.Oferta.Obtener): Observable<Mensaje.ObtenerOfertaRespuesta> {
    //     return this.gateway.post("Cotizacion/ContinuarOfertaCotizacion", modelo);
    // }

    // public reOfertarCotizacion(modelo: ApiModel.Oferta.Crear): Observable<Mensaje.ObtenerOfertaRespuesta> {
    //     return this.gateway.post("Cotizacion/CrearOfertaParaCotizacion", modelo);
    // }

    // public descartar(modelo: ApiModel.Cotizacion.Descartar): Observable<Mensaje.CotizacionDescartarRespuesta> {
    //     return this.gateway.post("Cotizacion/Descartar", modelo);
    // }

    // public noOfertar(modelo: ApiModel.Cotizacion.NoOfertar): Observable<Mensaje.CotizacionNoOfertarRespuesta> {
    //     return this.gateway.post("Cotizacion/NoOfertar", modelo);
    // }

    // public ofertar(modelo: ApiModel.Oferta.Documento): Observable<Mensaje.DocumentoOfertaRespuesta> {
    //     return this.gateway.post("Cotizacion/EnviarOferta", modelo);
    // }

    // public recuperar(modelo: ApiModel.Cotizacion.Recuperar): Observable<Mensaje.CotizacionDescartadaRespuesta> {
    //     return this.gateway.post("Cotizacion/Recuperar", modelo);
    // }

    // public guardarOferta(modelo: ApiModel.Oferta.Documento): Observable<Mensaje.DocumentoOfertaRespuesta> {
    //     return this.gateway.post("Cotizacion/GuardarOferta", modelo);
    // }

    // public eliminarOferta(modelo: ApiModel.Oferta.Eliminar): Observable<Mensaje.EliminarOfertaRespuesta> {
    //     return this.gateway.post("Cotizacion/EliminarOferta", modelo);
    // }

    // public obtenerPlantillaOferta(modelo: ApiModel.Cotizacion.PlantillaOferta): Observable<Mensaje.ObtenerPlantillaOfertaRespuesta> {
    //     return this.gateway.post("Cotizacion/ObtenerPlantillaOferta", modelo);
    // }

    // public listarMonedas = (): Observable<Dto.Moneda[]> => {
    //     return this.wrapper.wrap("listarMonedas", "Cotizacion/ListarMonedas");
    // }

    // public listarOpcionesEstadoOferta = (): Observable<Dto.Referencia[]> => {
    //     return this.wrapper.wrapperOpciones("listarOpcionesEstadoOferta", "Cotizacion/ListarOpcionesEstadoOferta");
    // }

    // public listarOpcionesEstadoCotizacion = (): Observable<Dto.Referencia[]> => {
    //     return this.wrapper.wrapperOpciones("listarOpcionesEstadoCotizacion", "Cotizacion/ListarOpcionesEstadoCotizacion");
    // }

    // public listarOpcionesFiltroCotizacion = (): Observable<Dto.Referencia[]> => {
    //     return this.wrapper.wrapperOpciones("listarOpcionesFiltroCotizacion", "Cotizacion/ListarOpcionesFiltroCotizacion");
    // }


}