﻿import { Injectable } from "@angular/core";
import { ServicioGateway } from "./gateway.service";
import { Observable } from "rxjs/Observable";

@Injectable()
export class EmpresaService {

    constructor(private gatewayService: ServicioGateway) { }

    public obtenerEmpresa(idEmpresa: string): Observable<Mensaje.ObtenerEmpresaRespuesta> {
        const url = "Empresa/Obtener";
        let modelo: ApiModel.ObtenerEmpresa = { Rut: "", Id: idEmpresa };
        return this.gatewayService.post<Mensaje.ObtenerEmpresaRespuesta>(url, modelo);
    }

    public obtenerEmpresaUsuario(): Observable<Mensaje.ObtenerEmpresaRespuesta> {
        const url = "Empresa/ObtenerEmpresaUsuario";
        return this.gatewayService.post<Mensaje.ObtenerEmpresaRespuesta>(url, <ApiModel.ObtenerEmpresa>{});
    }

}
