import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ApplicationInsights, IExceptionTelemetry, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from './../../environments/environment';
@Injectable({
    providedIn: 'root',
})
export class ApplicationInsightsService {
    private appInsights: ApplicationInsights;
    private isInitialPageLoad: boolean = true;
    constructor(private router: Router) {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.instrumentationKey
            }
        });

        this.appInsights.loadAppInsights();
        this.loadCustomTelemetryProperties();
        this.createRouterSubscription();
    }
    setUserId(userId: string) {
        this.appInsights.setAuthenticatedUserContext(userId);
    }
    clearUserId() {
        this.appInsights.clearAuthenticatedUserContext();
    }
    logPageView(name?: string, uri?: string) {
        this.appInsights.trackPageView({
            name,
            uri
        });
    }
    logException(error: Error) {
        let exception: IExceptionTelemetry = {
            exception: error
        };
        this.appInsights.trackException(exception);
    }
    private loadCustomTelemetryProperties() {
        this.appInsights.addTelemetryInitializer(envelope => {
            envelope.tags["ai.cloud.role"] =  "MarketPlace-MonitorObras";
            envelope.tags["ai.cloud.roleInstance"] = "MarketPlace-MonitorObras";
            var item = envelope.baseData;
            item.properties = item.properties || {};
            item.properties["ApplicationPlatform"] = "Web";
            item.properties["ApplicationName"] = "MarketPlaceMonitorObras";
            item.properties["UserEmail"] = JSON.parse(sessionStorage.getItem("icCurrentUser"))["email"];
        });
    }
    private createRouterSubscription() {
        this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
            if (!this.isInitialPageLoad)
              this.appInsights.startTrackPage(event.url);
            else
              this.isInitialPageLoad = false;
          });
          this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            if (!this.isInitialPageLoad)
              this.appInsights.stopTrackPage(event.urlAfterRedirects);
            else
              this.isInitialPageLoad = false;
          });
      
          if (this.isInitialPageLoad) {
            this.logPageView(this.router.url);
          }
    }
}

@Injectable()
export class ApplicationInsightsErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) { }
    handleError(error: any): void {
        this.injector.get<ApplicationInsightsService>(ApplicationInsightsService).logException(error);
    }
}
