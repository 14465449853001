import { NgModule } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";
import { IngresoComponent } from "./ingreso.component";

const appRoutes: Routes = [
  { path: 'ingreso', component: IngresoComponent },
  { path: 'ingreso/:path', component: IngresoComponent },
  { path: '', loadChildren: './monitor/catalogo/catalogo.module#CatalogoModule' },
  { path: 'catalogo', loadChildren: './monitor/catalogo/catalogo.module#CatalogoModule' },
  { path: 'obras/:tipoObra/:etapaObra/:rubroObra', loadChildren: './monitor/obras/obras.module#ObrasModule' },
  { path: 'detalle/:idObra/:tipoPlantilla', loadChildren: './monitor/detalle/detalle.module#DetalleObraModule' },
  { path: 'ficha-empresa/:idEmpresa/:idPanel', loadChildren: './monitor/ficha-empresa/ficha-empresa.module#FichaEmpresaModule' },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
