import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationInsightsErrorHandler } from './_services/application-insights.service';
import localeEsCL from '@angular/common/locales/es-CL';

import { SessionService } from './_services/session.service';
import { ServicioAutenticacion } from './_services/autenticacion.service';
import { ServicioUbicacionApi } from './_services/ubicacion.service';

import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { IngresoComponent } from './ingreso.component';

import { HelperService } from './_services/helper.service';
import { ServicioGateway } from './_services/gateway.service';
import { EmpresaService } from './_services/empresa.service';
import { MonitorService } from './_services/monitor.service';
import { Wrapper } from './_services/wrapper.service';
import { CookieService } from 'ngx-cookie-service';
import { ErrorInterceptor } from './_interceptors/errorhttp.interceptor';
import { ModalModule } from 'ngx-bootstrap/modal';
import { GlobalErrorHandler } from './_interceptors/global-error';

registerLocaleData(localeEsCL);

@NgModule({
    declarations: [
        AppComponent,
        IngresoComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        HttpClientModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        SessionService,
        ServicioAutenticacion,
        ServicioUbicacionApi,
        HelperService,
        ServicioGateway,
        EmpresaService,
        MonitorService,
        CookieService,
        Wrapper,
        { provide: LOCALE_ID, useValue: 'es-CL' },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: ErrorHandler, useClass: ApplicationInsightsErrorHandler },
        { provide: ErrorHandler, useClass: GlobalErrorHandler }]
})
export class AppModule { }
