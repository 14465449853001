//<reference path="../../../node_modules/@types/moment-timezone/index.d.ts" />
///<reference path="../../../node_modules/moment/moment.d.ts" />
import { Injectable } from "@angular/core";
import { ServicioUbicacionApi } from "./ubicacion.service";
import { ServicioAutenticacion } from "./autenticacion.service";
import { Observable } from "rxjs/Observable";
//import { _throw } from "rxjs/Observable/throw";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable()
export class ServicioGateway {

    constructor(private servicioAutenticacion: ServicioAutenticacion,
        private ubicacionServidorService: ServicioUbicacionApi,
        private http: HttpClient) { }

    public postAnonimo<T>(url: string, data: any): Observable<T> {
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json"
            })
        };

        return this.http.post<any>(this.ubicacionServidorService.getApiUrl(1) + url, data, httpOptions)
            .pipe(map(r => r))
            .catch(error => this.controlError(error));
    }

    public get<T>(url: string): Observable<T> {
        return this.servicioAutenticacion.execute().pipe(() => {
            const token = this.servicioAutenticacion.currentAccessTokenValue;
            const tokenPermisos = this.servicioAutenticacion.currentPermissionValue;
            const idSuscripcion = this.servicioAutenticacion.obtenerIdSuscripcion();

            if (token == null || idSuscripcion == null) {
                return Observable.fromPromise(Promise.reject("Usuario no logueado"));
            } else {
                const httpOptions = {
                    headers: new HttpHeaders({
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                        "X-Permission": tokenPermisos,
                        "IdSuscripcion": idSuscripcion
                    })
                };
                return this.http.get<any>(this.ubicacionServidorService.getApiUrl(1) + url, httpOptions).
                    pipe(map((r) => r))
                    .catch(error => this.controlError(error));
            }
        }).catch(error => {
            return Observable.fromPromise(Promise.reject(error));
        });
    }

    public post<T>(url: string, data: any): Observable<T> {
        return this.servicioAutenticacion.execute().pipe(() => {

            const token = this.servicioAutenticacion.currentAccessTokenValue;
            const tokenPermisos = this.servicioAutenticacion.currentPermissionValue;
            const idSuscripcion = this.servicioAutenticacion.obtenerIdSuscripcion();

            if (token == null || idSuscripcion == null) {
                return Observable.fromPromise(Promise.reject("Usuario no logueado"));
            } else {
                const httpOptions = {
                    headers: new HttpHeaders({
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                        "X-Permission": tokenPermisos,
                        "IdSuscripcion": idSuscripcion
                    })
                };
                return this.http.post<any>(this.ubicacionServidorService.getApiUrl(1) + url, data, httpOptions).
                    pipe(map((r) => r))
                    .catch(error => this.controlError(error));
            }
        }).catch(error => {
            return Observable.fromPromise(Promise.reject(error));
        });
    }

    private controlError = (error: Response | any) => {
        let errMsg: string;
        if (error) {
            if (error.status === 401) {
                this.servicioAutenticacion.refreshToken();
                return Observable.throw("Ha ocurrido un error, por favor intentar nuevamente");
            } else {
                const body = error.json() || "";
                const err = body || JSON.stringify(body);
                errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
            }
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return errMsg;
    }
}
