import { Injectable } from "@angular/core";
import { ServicioGateway } from "./gateway.service";
import { Observable } from "rxjs/Observable";
import 'rxjs/add/observable/of';
import { SessionService } from "./session.service";
import { environment } from "../../environments/environment";
@Injectable()
export class Wrapper<T>{
    constructor(private gateway: ServicioGateway, private session: SessionService) {

    }

    public wrap = (key: string, path: string): Observable<T[]> => {
        if (!environment.production) {
            this.session.set(key, null);
        }
        let existente: T[] = this.session.get(key);
        if (existente === null) {
            return this.gateway.
                get(path).
                map((resp: any) => {
                    this.session.set(key, resp.Listado);
                    return resp.Listado;
                });
        } else {
            return Observable.of(existente);
        }
    }
    
    public wrapperOpciones = (key: string, path: string): Observable<Dto.Referencia[]> => {
        let existente: Dto.Referencia[] = this.session.get(key);
        if (existente === null) {
            return this.gateway.
                get<Mensaje.ListarOpcionesRespuesta>(path).
                map((resp) => {
                    this.session.set(key, resp.Listado);
                    return resp.Listado;
                });
        } else {
            return Observable.of(existente);
        }
    }
}
