///<reference path="../../node_modules/@types/google.analytics/index.d.ts" />

import { OnInit, Component } from "@angular/core";

import { Router, NavigationEnd } from "@angular/router";
import { LocationStrategy } from "@angular/common";
import { SessionService } from "./_services/session.service";
import { environment } from "../environments/environment";
import { ApplicationInsightsService } from './_services/application-insights.service';
import { ServicioAutenticacion } from "./_services/autenticacion.service";

declare var gtag: any;
@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {

    constructor(private sessionService: SessionService,
        private _router: Router,
        private locationStrategy: LocationStrategy,
        private autenticationService: ServicioAutenticacion,
        private applicationInsightsService: ApplicationInsightsService) {

        const url = locationStrategy.path();
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://www.googletagmanager.com/gtag/js?id=" + environment.gTagManagerCode;
        (<any>document.head).prepend(script);

        this._router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const tree = this._router.parseUrl(this._router.url);
                if (tree.fragment) {
                    const element = document.querySelector("#" + tree.fragment);
                    if (element) {
                        element.scrollIntoView(<any>element);
                    }
                } else {
                    window.scrollTo(0, 0);
                }

                gtag("config", environment.gTagManagerCode, {
                    page_path: event.urlAfterRedirects
                });
                gtag("page", environment.gTagManagerCode, {
                    page_path: event.urlAfterRedirects
                });
            }
        });

        //#region codigoComentado
        //                          OTk5OTk5OS05            9999999-9
        //prueba marce          ->  WyI5OTk5OTk5OS05Il0=    99999999-9
        //muchas coitiuzciones  ->  WyI5OTU1MjY2MC0zIl0=    99552660-3
        //sodimac               ->  WyI5Njc5MjQzMC1LIl0=    96792430-K
        //sodimac 2             -> WyI5NDQ3OTAwMC00IiwiOTY3OTI0MzAtSyJd ["94479000-4","96792430-K"]
        //this.servicioAutenticacion.ingresarToken("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoicHJ1ZWJhcyIsIlBlcm1pc29zIjoiNTlhZDcyMDcxOGRmYjQ2MzNjNzJmNzhlIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiVXNlciIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2VtYWlsYWRkcmVzcyI6ImNlc2FyQGljb25zdHJ1eWUuY29tIiwiTm9tYnJlVXN1YXJpbyI6ImNlc2FyIGNvbnRyZXJhcyIsIklkVXN1YXJpbyI6ImFkbWluIiwiSWRFbXByZXNhIjoiMTMxNDkiLCJGZWNoYVB1YmxpY2FjaW9uIjoiMDkvMTMvMjAxOCAwMDowMDowMCIsImNsaSI6Ild5STVPVFUxTWpZMk1DMHpJbDA9IiwibmJmIjoxNTI0MzIxNDM0LCJleHAiOjE1MjQ1Mzc0MzQsImlzcyI6Imh0dHBzOi8vcWEuaWNvbnN0cnV5ZS5jbCIsImF1ZCI6IjA5OTE1M2MyNjI1MTQ5YmM4ZWNiM2U4NWUwM2YwMDIyIn0.75jVFXgXIrQ9s_SVoEPs8GSxbfwLQgcYECM4aPgwVEg");
        //#endregion
        this.sessionService.reset();
    }

    public ngOnInit() {
        this.autenticationService.start();
     }
}
